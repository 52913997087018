@import 'styles/constants';
@import 'styles/mixins/fonts';
@import 'styles/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.hero_container {
	position: relative;

	margin-bottom: 4rem;

	width: 100%;
}

.logo {
	width: 210px;
	height: auto;
	z-index: 2;

	@include xs-only {
		width: 80px;
	}
}

.strava_badge {
	display: block;
	width: 300px;
	height: auto;
	z-index: 20;

	@include xs-only {
		width: 110px;
	}
}

.hero_image {
	position: relative;
	z-index: 0;

	img {
		max-height: calc(100dvh - 40px);
	}

	.watermark {
		font-size: 12px;
		font-weight: 400;
		position: absolute;
		bottom: 15px;
		right: 15px;
		z-index: 2;
	}

	@include xs-only {
		aspect-ratio: 390 / 693;

		img {
			height: 100%;
		}

		.watermark {
			font-size: 9px;
			bottom: 5px;
			right: 5px;
		}
	}
}

.hero_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 75px;
	width: 100%;
	height: 100%;
	z-index: 3;
	position: relative;

	> div {
		display: flex;
		column-gap: 50px;
		justify-content: center;
		align-items: center;

		@include xs-only {
			column-gap: 20px;
		}
	}

	@include sm-down {
		padding: 80px 10px;
		row-gap: 30px;
	}

	> h3 {
		@include headline3;
		text-align: center;
		margin: 0 auto;

		@include xs-only {
			@include headline8;
		}
	}
}

.shade {
	position: absolute;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 2;
}
