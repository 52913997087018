@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.hero_full_background {
	position: relative;
	overflow: hidden;
	background: rgba(0 0 0 / 30%);
	min-height: min(100vh, 700px);
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex: 1 0 0;

	@include xs-only {
		min-height: calc(100dvh - 35px);
	}
}

.gradient_background {
	position: absolute;
	filter: blur(200px);
	z-index: 1;
	pointer-events: none;
	inset: 0;
}

.cnt {
	--margin-bottom: 32px;
	color: #fff;
	padding: 32px;
	max-width: 640px;
	display: flex;
	flex-direction: column;

	@include sm-down {
		padding: 0;
		flex: 1 0 0;

		--margin-bottom: 24px;
	}

	.partner_name {
		@include headline3;
		margin-bottom: var(--margin-bottom);
		text-align: center;

		@include sm-down {
			font-size: 2rem;
			font-weight: 500;
			margin-top: var(--margin-bottom);
		}
	}

	.partner_quote {
		@include headline7;
		margin-bottom: var(--margin-bottom);
		text-align: center;

		@include sm-down {
			font-size: 1.2rem;
			font-weight: 400;
			margin-bottom: 0;
		}
	}

	.partner_quote2 {
		@include body1;
		margin-bottom: var(--margin-bottom);
		text-align: center;

		@include sm-down {
			font-size: 1.2rem;
			max-width: 300px;
		}
	}

	> a {
		margin-inline: auto;
		margin-top: var(--margin-bottom);

		span {
			font-weight: 500;
		}

		@include sm-down {
			margin-bottom: 3rem;
		}
	}
}

.new_hero {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	background-color: transparent;
	margin-inline: auto;
	column-gap: 0;
	overflow: visible;
	position: relative;
	z-index: 2;

	@include sm-down {
		display: flex;
		flex-direction: column;
	}

	.hero_img {
		height: 100%;
		display: flex;
		align-items: center;

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
			object-position: center bottom;
		}

		.circle_image {
			object-fit: cover !important;
			object-position: center;
			border-radius: 50%;
			max-width: 500px;
			max-height: 500px;
			margin-bottom: 50px;
			box-shadow: 0 50px 100px -60px rgba(0 0 0 / 30%);

			@include sm-down {
				aspect-ratio: 1;
				width: 100%;
				height: auto;
			}
		}

		@include sm-down {
			margin-top: 10px;
			padding: 0 10px;
			flex: 1 0 0;

			img.main_image {
				border-radius: 50%;
				border: 1px solid rgba(255 255 255 / 10%);
				box-shadow: 0 50px 100px -60px rgba(0 0 0 / 50%);
				object-position: top center;
				object-fit: cover !important;
				aspect-ratio: 1 / 1;
				height: auto;
			}
		}
	}
}

.subheadline {
	@include headline5;
	margin-bottom: 0;
	text-align: center;
	font-weight: 500;

	@include sm-down {
		font-size: 1.5rem;
	}
}

.subtitle {
	@include headline6;
	margin-bottom: 0;
	text-align: center;

	@include sm-down {
		font-size: 1.5rem;
	}
}

.author {
	@include body1;
	margin-bottom: 0;
	text-align: center;
	font-size: 1.1rem;
	display: grid;
	justify-content: center;
	align-items: center;

	> span:first-of-type {
		font-weight: 500;
	}

	@include sm-down {
		margin-top: 1.5rem;
		margin-bottom: 0.5rem;
	}
}
